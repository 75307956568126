import React from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import iponeImg from '../../assets/Images/iPhone-Logo.png';
import samsungImg from '../../assets/Images/Samsung-Logo.png';
import oneplusImg from '../../assets/Images/OnePlus-Logo.png';
import googleImg from '../../assets/Images/Google__G.png';
import nothingImg from '../../assets/Images/Nothing_Logo.png';
import oppoImg from '../../assets/Images/OPPO_LOGO.png';


const CarouselBrand = () => {
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 5
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    const brandData = [
        {
            ID: 1,
            imgUrl: iponeImg,

        },
        {
            ID: 2,
            imgUrl: samsungImg,

        },
        {
            ID: 3,
            imgUrl: oneplusImg,

        },
        {
            ID: 4,
            imgUrl: googleImg,

        },
        {
            ID: 5,
            imgUrl: nothingImg,

        },
        {
            ID: 6,
            imgUrl: oppoImg,

        },
        {
            ID: 5,
            imgUrl: nothingImg,

        },
        {
            ID: 6,
            imgUrl: oppoImg,

        },
        {
            ID: 3,
            imgUrl: oneplusImg,

        },
    ]

    return (
        <div>

             <Carousel responsive={responsive}
             infinite={true}
             autoPlay={ true}
             autoPlaySpeed={3000}
             keyBoardControl={true}
             customTransition="all 2"
             transitionDuration={700}
            >
                {brandData.map((item, index) => (
                    <div key={item.ID}>
                        <div className='brand-img'>
                            <img src={item.imgUrl} alt="" width={100} height={100} />
                        </div>
                        <div>
                            {item.heading}
                        </div>
                    </div>
                ))}
            </Carousel> 
        </div>
    )
}

export default CarouselBrand;
