import React from 'react'
import '../../assets/css/Cart.css'
import designdemo from '../../assets/Images/iPhone_16-removebg-preview.png'
const ViewCart = () => {
  return (
    <section class="mt-4 mb-4">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-7 col-md-7 col-sm-7 justify-content-center">
                    <div class="card">
                        <div class="card-shop">
                            <div class="card-shop-second">
                            <div class="row">
                                <div class="col">
                                    <h4>Cart Shopping</h4>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12 col-md-12 mb-3 my-1">
                                    <div class="inner-card">
                                        <div class="row">
                                        <div class="col-lg-3">
                                            <div class="item-box">
                                                <img src={designdemo} class="img-fluid" alt=""/>
                                            </div>
                                        </div>
                                        <div class="col-lg-9">
                                            <div class="item-text-box ">
                                                <div>
                                                    <p class="para-1">iPhone 16 Pro Baadshah</p>
                                                    <p class="para-2">₹ 599.00Sale <del class="pricer">Price₹ 699.00</del></p>
                                                     <p class="para-3">Quantity : 1</p>
                                                     <p class="para-4">Back+Camera</p>
                                                </div>
                                                <div class="remove-btn"><button>Remove</button></div>
                                            </div>
                                        </div>
                                        </div>
                                        
                                        
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12 mb-3 my-1">
                                    <div class="inner-card">
                                        <div class="row">
                                        <div class="col-lg-3">
                                            <div class="item-box">
                                                <img src={designdemo} class="img-fluid" alt=""/>
                                            </div>
                                        </div>
                                        <div class="col-lg-9">
                                            <div class="item-text-box ">
                                                <div>
                                                    <p class="para-1">iPhone 16 Pro Baadshah</p>
                                                    <p class="para-2">₹ 599.00Sale <del class="pricer">Price₹ 699.00</del></p>
                                                     <p class="para-3">Quantity : 1</p>
                                                     <p class="para-4">Back+Camera</p>
                                                </div>
                                                <div class="remove-btn"><button>Remove</button></div>
                                            </div>
                                        </div>
                                        </div>
                                        
                                        
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12 mb-3 my-1">
                                    <div class="inner-card">
                                        <div class="row">
                                        <div class="col-lg-3">
                                            <div class="item-box">
                                                <img src={designdemo} class="img-fluid" alt=""/>
                                            </div>
                                        </div>
                                        <div class="col-lg-9">
                                            <div class="item-text-box ">
                                                <div>
                                                    <p class="para-1">iPhone 16 Pro Baadshah</p>
                                                    <p class="para-2">₹ 599.00Sale <del class="pricer">Price₹ 699.00</del></p>
                                                     <p class="para-3">Quantity : 1</p>
                                                     <p class="para-4">Back+Camera</p>
                                                </div>
                                                <div class="remove-btn"><button>Remove</button></div>
                                            </div>
                                        </div>
                                        </div>
                                        
                                        
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                        </div>
                    </div>
                    
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4">
                    <div class="card-total">
                        <div class="card-total-inner">
                            <div class="mb-4 promo-main">
                                <label class="mb-1 pro-label" for="#"> Promo Code</label>
                            <input class="promocode" type="text"/>
                            <button class="promo-btn">Apply</button>
                            </div>
                            
                            <ul class="ul-list-card">
                                <div class="ul-list-card-1">
                                    <li class="subtitle">Subtotal: </li>
                                <li class="number-nine">₹ 599.00 </li>
                                </div>
                                <div class="ul-list-card-1">
                                    <li class="subtitle-1">Discount </li>
                                <li class="number-nine-1">₹ 299.00 </li>
                                </div>

                                <div class="ul-list-card-1">
                                    <li class="subtitle-total">Total </li>
                                <li class="number-nine-total">₹ 2999.00 </li>
                                </div>
                                
                            </ul>
                            <p class="shipping">Shipping & taxes calculated at checkout</p>
                            <div class="btn-add-cart">CHECKOUT</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

  )
}

export default ViewCart
