import React from 'react';
import logoMobile from '../../assets/Images/logom.png';



export const Header = () => {
  return (
    <>

<nav class="navbar sticky-top navbar-expand-lg navbar-light bg-white navone">
   
      <a class="navbar-brand" href="#"><img src={logoMobile} alt="" width={90}/> </a>
      <div className='mob-deive'>
      <button class="navbar-toggler collapsed  order-1" type="button" data-bs-toggle="collapse" data-bs-target="#navbar-content">
        <div class="hamburger-toggle">
          <div class="hamburger">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </button>
      <div className='full-screentb'>
      <ul class="navbar-nav ms-auto mb-2 mb-lg-0 profile-menu"> 
        <li class="nav-item dropdown">
          <a class="nav-link profile-click dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
          <i class="ri-user-fill"></i>
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
            <li><a class="dropdown-item" href="#"><i class="ri-shopping-cart-2-line"></i> Add to Cart</a></li>
            <li><a class="dropdown-item" href="#"><i class="ri-settings-4-line"></i> Settings</a></li>
            <li><hr class="dropdown-divider"/></li>
            <li><a class="dropdown-item" href="#"><i class="ri-logout-circle-r-line"></i> Log Out</a></li>
          </ul>
        </li>
     </ul>
      </div>
      </div>
      
     
      <div class="collapse navbar-collapse nav-moble-collape" id="navbar-content">
        <ul class="navbar-nav mx-auto mb-2 mb-lg-0">
         

          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle " href="#" data-bs-toggle="dropdown" data-bs-auto-close="outside">Multilevel <i class="ri-arrow-down-s-line"></i></a>
            <ul class="dropdown-menu shadow">
             
              <li class="dropend">
                <a href="#" class="dropdown-item dropdown-toggle dtgl"  data-bs-toggle="dropdown" data-bs-auto-close="outside">Iphone <i class="ri-arrow-right-s-line"></i></a>
                <ul class="dropdown-menu shadow">
                  <li><a class="dropdown-item" href=""> Second level 1</a></li>
                  <li><a class="dropdown-item" href=""> Second level 2</a></li>
                  <li><a class="dropdown-item" href=""> Second level 3</a></li>
                  <li><a class="dropdown-item" href=""> Third level 5</a></li>
                </ul>
              </li>

              <li class="dropend">
                <a href="#" class="dropdown-item dropdown-toggle" data-bs-toggle="dropdown" data-bs-auto-close="outside">Sumsang <i class="ri-arrow-right-s-line"></i></a>
                <ul class="dropdown-menu shadow">
                  <li><a class="dropdown-item" href=""> Second level 1</a></li>
                  <li><a class="dropdown-item" href=""> Second level 2</a></li>
                  <li><a class="dropdown-item" href=""> Second level 3</a></li>
                  
                  <li><a class="dropdown-item" href=""> Third level 5</a></li>
                </ul>
              </li>

              <li class="dropend">
                <a href="#" class="dropdown-item dropdown-toggle" data-bs-toggle="dropdown" data-bs-auto-close="outside">OnePlus <i class="ri-arrow-right-s-line"></i></a>
                <ul class="dropdown-menu shadow">
                  <li><a class="dropdown-item" href=""> Second level 1</a></li>
                  <li><a class="dropdown-item" href=""> Second level 2</a></li>
                  <li><a class="dropdown-item" href=""> Second level 3</a></li>
                  
                  <li><a class="dropdown-item" href=""> Third level 5</a></li>
                </ul>
              </li>
              
              <li class="dropend">
                <a href="#" class="dropdown-item dropdown-toggle" data-bs-toggle="dropdown" data-bs-auto-close="outside">Nothing <i class="ri-arrow-right-s-line"></i></a>
                <ul class="dropdown-menu shadow">
                  <li><a class="dropdown-item" href=""> Second level 1</a></li>
                  <li><a class="dropdown-item" href=""> Second level 2</a></li>
                  <li><a class="dropdown-item" href=""> Second level 3</a></li>
                 
                  <li><a class="dropdown-item" href=""> Third level 5</a></li>
                </ul>
              </li>

              <li class="dropend">
                <a href="#" class="dropdown-item dropdown-toggle" data-bs-toggle="dropdown" data-bs-auto-close="outside">Pixel <i class="ri-arrow-right-s-line"></i></a>
                <ul class="dropdown-menu shadow">
                  <li><a class="dropdown-item" href=""> Second level 1</a></li>
                  <li><a class="dropdown-item" href=""> Second level 2</a></li>
                  <li><a class="dropdown-item" href=""> Second level 3</a></li>
                 
                  <li><a class="dropdown-item" href=""> Third level 5</a></li>
                </ul>
              </li>

              <li class="dropend">
                <a href="#" class="dropdown-item dropdown-toggle" data-bs-toggle="dropdown" data-bs-auto-close="outside">Tecno <i class="ri-arrow-right-s-line"></i></a>
                <ul class="dropdown-menu shadow">
                  <li><a class="dropdown-item" href=""> Second level 1</a></li>
                  <li><a class="dropdown-item" href=""> Second level 2</a></li>
                  <li><a class="dropdown-item" href=""> Second level 3</a></li>
                  
                  <li><a class="dropdown-item" href=""> Third level 5</a></li>
                </ul>
              </li>

              <li class="dropend">
                <a href="#" class="dropdown-item dropdown-toggle" data-bs-toggle="dropdown" data-bs-auto-close="outside">Asus <i class="ri-arrow-right-s-line"></i></a>
                <ul class="dropdown-menu shadow">
                  <li><a class="dropdown-item" href=""> Second level 1</a></li>
                  <li><a class="dropdown-item" href=""> Second level 2</a></li>
                  <li><a class="dropdown-item" href=""> Second level 3</a></li>
                
                  <li><a class="dropdown-item" href=""> Third level 5</a></li>
                </ul>
              </li>

              <li class="dropend">
                <a href="#" class="dropdown-item dropdown-toggle" data-bs-toggle="dropdown" data-bs-auto-close="outside">Xiaomi <i class="ri-arrow-right-s-line"></i></a>
                <ul class="dropdown-menu shadow">
                  <li><a class="dropdown-item" href=""> Second level 1</a></li>
                  <li><a class="dropdown-item" href=""> Second level 2</a></li>
                  <li><a class="dropdown-item" href=""> Second level 3</a></li>
                
                  <li><a class="dropdown-item" href=""> Third level 5</a></li>
                </ul>
              </li>

              <li class="dropend">
                <a href="#" class="dropdown-item dropdown-toggle" data-bs-toggle="dropdown" data-bs-auto-close="outside">POCO <i class="ri-arrow-right-s-line"></i></a>
                <ul class="dropdown-menu shadow">
                  <li><a class="dropdown-item" href=""> Second level 1</a></li>
                  <li><a class="dropdown-item" href=""> Second level 2</a></li>
                  <li><a class="dropdown-item" href=""> Second level 3</a></li>
                
                  <li><a class="dropdown-item" href=""> Third level 5</a></li>
                </ul>
              </li>

              <li class="dropend">
                <a href="#" class="dropdown-item dropdown-toggle" data-bs-toggle="dropdown" data-bs-auto-close="outside">realme <i class="ri-arrow-right-s-line"></i></a>
                <ul class="dropdown-menu shadow">
                  <li><a class="dropdown-item" href=""> Second level 1</a></li>
                  <li><a class="dropdown-item" href=""> Second level 2</a></li>
                  <li><a class="dropdown-item" href=""> Second level 3</a></li>
                
                  <li><a class="dropdown-item" href=""> Third level 5</a></li>
                </ul>
              </li>

              <li class="dropend">
                <a href="#" class="dropdown-item dropdown-toggle" data-bs-toggle="dropdown" data-bs-auto-close="outside">iQOO <i class="ri-arrow-right-s-line"></i></a>
                <ul class="dropdown-menu shadow">
                  <li><a class="dropdown-item" href=""> Second level 1</a></li>
                  <li><a class="dropdown-item" href=""> Second level 2</a></li>
                  <li><a class="dropdown-item" href=""> Second level 3</a></li>
                
                  <li><a class="dropdown-item" href=""> Third level 5</a></li>
                </ul>
              </li>

              <li class="dropend">
                <a href="#" class="dropdown-item dropdown-toggle" data-bs-toggle="dropdown" data-bs-auto-close="outside">Oppo <i class="ri-arrow-right-s-line"></i></a>
                <ul class="dropdown-menu shadow">
                  <li><a class="dropdown-item" href=""> Second level 1</a></li>
                  <li><a class="dropdown-item" href=""> Second level 2</a></li>
                  <li><a class="dropdown-item" href=""> Second level 3</a></li>
                
                  <li><a class="dropdown-item" href=""> Third level 5</a></li>
                </ul>
              </li>
              <li class="dropend">
                <a href="#" class="dropdown-item dropdown-toggle" data-bs-toggle="dropdown" data-bs-auto-close="outside">VIVI <i class="ri-arrow-right-s-line"></i></a>
                <ul class="dropdown-menu shadow">
                  <li><a class="dropdown-item" href=""> Second level 1</a></li>
                  <li><a class="dropdown-item" href=""> Second level 2</a></li>
                  <li><a class="dropdown-item" href=""> Second level 3</a></li>
                
                  <li><a class="dropdown-item" href=""> Third level 5</a></li>
                </ul>
              </li>
             
            </ul>
          </li>

          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown" data-bs-auto-close="outside">Laptops Skins <i class="ri-arrow-down-s-line"></i></a>
            <ul class="dropdown-menu shadow">
             
              <li class="dropend">
                <a href="#" class="dropdown-item dropdown-toggle" data-bs-toggle="dropdown" data-bs-auto-close="outside">Macbook <i class="ri-arrow-right-s-line"></i></a>
                <ul class="dropdown-menu shadow">
                  <li><a class="dropdown-item" href=""> Second level 1</a></li>
                  <li><a class="dropdown-item" href=""> Second level 2</a></li>
                  <li><a class="dropdown-item" href=""> Second level 3</a></li>
                  <li><a class="dropdown-item" href=""> Third level 5</a></li>
                </ul>
              </li>

              <li class="dropend">
                <a href="#" class="dropdown-item dropdown-toggle" data-bs-toggle="dropdown" data-bs-auto-close="outside">Windows <i class="ri-arrow-right-s-line"></i></a>
                <ul class="dropdown-menu shadow">
                  <li><a class="dropdown-item" href=""> Second level 1</a></li>
                  <li><a class="dropdown-item" href=""> Second level 2</a></li>
                  <li><a class="dropdown-item" href=""> Second level 3</a></li>
                  
                  <li><a class="dropdown-item" href=""> Third level 5</a></li>
                </ul>
              </li>

              

             
            </ul>
          </li>

          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown" data-bs-auto-close="outside">Skins collection <i class="ri-arrow-down-s-line"></i></a>
            <ul class="dropdown-menu shadow">
            <li><a class="dropdown-item" href=""> Second level 1</a></li>
            <li><a class="dropdown-item" href=""> Second level 1</a></li>
            <li><a class="dropdown-item" href=""> Second level 1</a></li>
            <li><a class="dropdown-item" href=""> Second level 1</a></li>
            <li><a class="dropdown-item" href=""> Second level 1</a></li>
              {/* <li class="dropend">
                <a href="#" class="dropdown-item dropdown-toggle" data-bs-toggle="dropdown" data-bs-auto-close="outside">Macbook <i class="ri-arrow-right-s-line"></i></a>
                <ul class="dropdown-menu shadow">
                  <li><a class="dropdown-item" href=""> Second level 1</a></li>
                  <li><a class="dropdown-item" href=""> Second level 2</a></li>
                  <li><a class="dropdown-item" href=""> Second level 3</a></li>
                  <li><a class="dropdown-item" href=""> Third level 5</a></li>
                </ul>
              </li> */}

            </ul>
          </li>




          <li class="nav-item">
            <a class="nav-link active" aria-current="page" href="#">Build Your Device Skins</a>
          </li>

          <li class="nav-item">
            <a class="nav-link" href="#">How to Apply</a>
          </li>
         
        </ul>
        <div class="d-flex ms-auto">
            <div class="input-group d-flex gap-3">
                <button class="btn-log  border-0" >Login</button>
                {/* <button class="btn-sign  border-0" >Sign Up</button> */}
                <ul class="navbar-nav ms-auto mb-2 mb-lg-0 profile-menu d-none d-sm-none d-md-none d-lg-block d-xl-block"> 
        <li class="nav-item dropdown">
          <a class="nav-link profile-click dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
          <i class="ri-user-fill"></i>
          </a>
          <ul class="dropdown-menu list-item-one" aria-labelledby="navbarDropdown">
            <div>
            <li><a class="dropdown-item" href="#"><i class="ri-shopping-cart-2-line"></i> Add to Cart</a></li>
            <li><a class="dropdown-item" href="#"><i class="ri-settings-4-line"></i> Settings</a></li>
            <li><hr class="dropdown-divider"/></li>
            <li><a class="dropdown-item" href="#"><i class="ri-logout-circle-r-line"></i> Log Out</a></li>
            </div>
            
          </ul>
        </li>
     </ul>
            </div>
        </div>
      </div>
   
  </nav>



    </>

  )
}
