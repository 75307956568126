import React from 'react'
import mobileLogo from '../../assets/Images/mobile-logo.png';
export const Signup = () => {
  return (
    <section className="fxt-template-animation fxt-template-layout21 pt-4" id='bglog-bg'>
    <div class="container">
        <div class="row align-items-center justify-content-center">
            <div class="col-xl-6 col-lg-7 col-sm-12 col-12 fxt-bg-color">
                <div class="fxt-content">
                    <div class="fxt-header">
                        <a href="login-21.html" class="fxt-logo"><img src={mobileLogo} alt="" width={100} height={70} /></a>
                        <p>Login into your pages account</p>
                    </div>
                    <div class="fxt-form">
                        <form method="POST">
                          <div className='row'>
                            <div className='col-lg-6 col-md-6'>
                            <div class="form-group">
                                <div class="fxt-transformY-50 fxt-transition-delay-1">
                                    <input type="text" id="username" class="form-control" name="username" placeholder="Username" required="required"/>
                                </div>
                            </div>
                            </div>
                            <div className='col-lg-6 col-md-6'>
                            <div class="form-group">
                                <div class="fxt-transformY-50 fxt-transition-delay-1">
                                    <input type="email" id="email" class="form-control" name="email" placeholder="Email" required="required"/>
                                </div>
                            </div>
                            </div>
                            <div className='col-lg-6 col-md-6'>
                            <div class="form-group">
                                <div class="fxt-transformY-50 fxt-transition-delay-1">
                                    <input type="number" id="number" class="form-control" name="number" placeholder="Number" required="required"/>
                                </div>
                            </div>
                            </div>
                            <div className='col-lg-6 col-md-6'>
                            <div class="form-group">
                                <div class="fxt-transformY-50 fxt-transition-delay-2">
                                    <input id="password" type="password" class="form-control" name="password" placeholder="********" required="required"/>
                                    <i toggle="#password" class="fa fa-fw fa-eye toggle-password field-icon"></i>
                                </div>
                            </div>
                            </div>
                          </div>
                          
                           
                           
                           
                            <div class="form-group">
                                <div class="fxt-transformY-50 fxt-transition-delay-3">
                                    <div class="fxt-checkbox-area">
                                        <div class="checkbox">
                                            <input id="checkbox1" type="checkbox"/>
                                            <label for="checkbox1">Keep me logged in</label>
                                        </div>
                                        <a href="forgot-password-21.html" class="switcher-text">Forgot Password</a>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="fxt-transformY-50 fxt-transition-delay-4">
                                    <button type="submit" class="fxt-btn-fill">SignUp</button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="fxt-style-line">
                        <div class="fxt-transformY-50 fxt-transition-delay-5">
                            <h3>Or Login With</h3>
                        </div>
                    </div>
                    <ul class="fxt-socials">
                        <li class="fxt-google">
                            <div class="fxt-transformY-50 fxt-transition-delay-6">
                                <a href="#" title="google"><i class="fab fa-google-plus-g"></i><span>Google +</span></a>
                            </div>
                        </li>
                        <li class="fxt-twitter">
                            <div class="fxt-transformY-50 fxt-transition-delay-7">
                                <a href="#" title="twitter"><i class="fab fa-twitter"></i><span>Twitter</span></a>
                            </div>
                        </li>
                        <li class="fxt-facebook">
                            <div class="fxt-transformY-50 fxt-transition-delay-8">
                                <a href="#" title="Facebook"><i class="fab fa-facebook-f"></i><span>Facebook</span></a>
                            </div>
                        </li>
                    </ul>
                    <div class="fxt-footer">
                        <div class="fxt-transformY-50 fxt-transition-delay-9">
                            <p>Don't have an account?<a href="register-21.html" class="switcher-text2 inline-text">Register</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> 
  )
}
