import React from 'react';
import { Outlet } from 'react-router-dom';
import { Header } from '../MainLayouts/Header';
import { Footer } from '../MainLayouts/Footer';
import 'remixicon/fonts/remixicon.css'
import '../../assets/css/style.css'
const Main = ({children}) => {
  return (
    <>
      <div>
        <Header />
        <Outlet/>
        {children}
         <Footer/>
      </div>


    </>
  )
}

export default Main