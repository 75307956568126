import React from 'react'
import ProductPhot from '../../assets/Images/mobile-cover.png'
import '../../assets/css/Product.css'
const ProductDetails = () => {
  return (
    <section class="shoppin-buy my-4">
    <div class="container">
     <div class="row">
         <div class="col-lg-10 mx-auto">
             <div class="buy-main-t">
                 <div class="row">
                     <div class="col-lg-6 col-md-6 ">
                         <div class="shopping-img-buy text-center h-100">
                         <div id="carouselExample" class="carousel slide">
   <div className='product-ss'>
   <div class="carousel-inner">
    <div class="carousel-item  active">
      <img src={ProductPhot}  class="d-block w-100" alt="..."/>
    </div>
    <div class="carousel-item ">
      <img src={ProductPhot}  class="d-block w-100" alt="..."/>
    </div>
    
  </div>
    </div>                         
 <div className='bot-arrow'>
 <button class="carousel-control-prev" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
    <div>
    <span class="carousel-control-prev-icon" aria-hidden="true">
   
    </span>
    </div>
   
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
    <div>
    <span class="carousel-control-next-icon" aria-hidden="true">
    
    </span>
    </div>
    
    <span class="visually-hidden">Next</span>
  </button>
 </div>
 
</div>
                             {/* <div class="buy-img-shop">
                                 <img src={ProductPhot} class="img-fluid" alt="..."/>
                             </div> */}
                         </div>
                         </div>
                        
                         <div class="col-lg-6 col-md-6">
                             <div class="shopping-img-price d-flex align-items-center justify-content-center  h-100">
                                 <div class="shop-sderow">
                                    <p className='m-0'>Samsung Galaxy S24 Plus Graffiti</p>
                                    <h2>Grafitti</h2>
                                    <p className='pro-para'><span className='res'>₹ 599.00</span> 
                                    <span>MRP(inclusive of taxes):</span> 
                                  <del className='del-pro'>₹ 699.00</del> </p>
                                     <button class="select-design">Select Design</button>
                                  
                                 </div>
                             </div>
                         </div>
                 </div>
             </div>
             
         </div>
   
 
     </div>
    </div>
   </section>
 
  )
}

export default ProductDetails
