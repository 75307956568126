import React from 'react'

const Checkout = () => {
  return (
    <div>
      Here user fuill final data and payment to confirm order
    </div>
  )
}

export default Checkout
