import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import React from 'react';
import Main from './components/Main/Main';
import { Home } from "./components/Childrencomponets/Home";
import { Login } from "./components/Authcomponent/Login";
import { Signup } from "./components/Authcomponent/Signup";
import BrandCollection from "./components/CollectionComponents/BrandCollection"
import BrandModelsCollections from "./components/CollectionComponents/BrandModelsCollections"
import BrandModelSkinCollections from "./components/CollectionComponents/BrandModelSkinCollections"
import ProductDetails from "./components/ProductComponent/ProductDetails";
import Cart from "./components/Childrencomponets/ViewCart";
import Checkout from "./components/Childrencomponets/Checkout";
function App() {
  return (
    <Router>
          <Routes>
           {/* Routes that should include Header and Footer */}
            <Route element={<Main />}>
              <Route path="/" element={<Home />} />
              <Route path="/BrandModelSkinCollections" element={<BrandModelSkinCollections />} />
              <Route path="/BrandCollection" element={<BrandCollection />} />
              <Route path="/BrandModelsCollections" element={<BrandModelsCollections />} />
              <Route path="/ProductDetails" element={<ProductDetails />} />
              <Route path="/Cart" element={<Cart />} />
              <Route path="/Checkout" element={<Checkout />} />
              <Route path="/Register" element={<Signup />} />
            </Route>

            {/* Routes that should not include Header and Footer */}
            <Route>
              <Route path="auth/Login" element={<Login />} />
            </Route>
          
           
            {/* <Route path="*" element={<Nopage />} /> */}
          </Routes>
        </Router>

  );
}

export default App;
