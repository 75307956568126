import React from 'react'
import iponeImg from '../../assets/Images/iPhone-Logo.png';
import samsungImg from '../../assets/Images/Samsung-Logo.png';
import oneplusImg from '../../assets/Images/OnePlus-Logo.png';
import googleImg from '../../assets/Images/Google__G.png';
import nothingImg from '../../assets/Images/Nothing_Logo.png';
import oppoImg from '../../assets/Images/OPPO_LOGO.png';
const BrandCollection = () => {
  return (
    <>
    <section className='brandco-1'>
    <div className='container-fluid'>
        <div className='row'>
          <div className='col-lg-3 col-md-3'>
          <div className='brand-img'>
            <img src={iponeImg} alt="" width={100} height={100} />
          </div>
          </div>
          <div className='col-lg-3 col-md-3'>
          <div className='brand-img'>
            <img src={samsungImg} alt="" width={100} height={100} />
          </div>
          </div>
          <div className='col-lg-3 col-md-3'>
          <div className='brand-img'>
            <img src={oneplusImg} alt="" width={100} height={100} />
          </div>
          </div>
          <div className='col-lg-3 col-md-3'>
          <div className='brand-img'>
            <img src={googleImg} alt="" width={100} height={100} />
          </div>
          </div>
          <div className='col-lg-3 col-md-3'>
          <div className='brand-img'>
            <img src={oneplusImg} alt="" width={100} height={100} />
          </div>
          </div>
          <div className='col-lg-3 col-md-3'>
          <div className='brand-img'>
            <img src={nothingImg} alt="" width={100} height={100} />
          </div>
          </div>
          <div className='col-lg-3 col-md-3'>
          <div className='brand-img'>
            <img src={oppoImg} alt="" width={100} height={100} />
          </div>
          </div>
        </div>
      </div>
    </section>
      
    </>
  )
}

export default BrandCollection
