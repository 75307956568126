import React from 'react'
import sumsangS from '../../assets/Images/galaxy-s-1.png';
import sumsangTwo from '../../assets/Images/galaxy-s-2.png';
import sumsangThree from '../../assets/Images/galaxy-s-3.png';
import sumsangFour from '../../assets/Images/galaxy-s-4.png';
const BrandModelSkinCollections = () => {
  return (
    <div>
        <div className='collap-tag'><span><a href="#">home</a></span> / <span><a href="#">collection</a> </span> / <span><a href="#">Samsung Galaxy S24 Plus</a></span></div>
             <section className='brand-gap-mobile'>
    {/* <h2 className='text-start mb-4 select-1 px-3'>Best Selling Mobile Skins</h2> */}
    <div className='container-fluid'>
      <div className='row'>
        <div  className='col-lg-3 col-md-3 mb-3'>
          <div class="card">
            <section class="card__hero">
              {/* <header class="card__hero-header mt-1 mb-2">
                <span className='h6'>Select your device</span>
                <div class="card__icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-hand-index-thumb" viewBox="0 0 16 16">
<path d="M6.75 1a.75.75 0 0 1 .75.75V8a.5.5 0 0 0 1 0V5.467l.086-.004c.317-.012.637-.008.816.027.134.027.294.096.448.182.077.042.15.147.15.314V8a.5.5 0 0 0 1 0V6.435l.106-.01c.316-.024.584-.01.708.04.118.046.3.207.486.43.081.096.15.19.2.259V8.5a.5.5 0 1 0 1 0v-1h.342a1 1 0 0 1 .995 1.1l-.271 2.715a2.5 2.5 0 0 1-.317.991l-1.395 2.442a.5.5 0 0 1-.434.252H6.118a.5.5 0 0 1-.447-.276l-1.232-2.465-2.512-4.185a.517.517 0 0 1 .809-.631l2.41 2.41A.5.5 0 0 0 6 9.5V1.75A.75.75 0 0 1 6.75 1M8.5 4.466V1.75a1.75 1.75 0 1 0-3.5 0v6.543L3.443 6.736A1.517 1.517 0 0 0 1.07 8.588l2.491 4.153 1.215 2.43A1.5 1.5 0 0 0 6.118 16h6.302a1.5 1.5 0 0 0 1.302-.756l1.395-2.441a3.5 3.5 0 0 0 .444-1.389l.271-2.715a2 2 0 0 0-1.99-2.199h-.581a5 5 0 0 0-.195-.248c-.191-.229-.51-.568-.88-.716-.364-.146-.846-.132-1.158-.108l-.132.012a1.26 1.26 0 0 0-.56-.642 2.6 2.6 0 0 0-.738-.288c-.31-.062-.739-.058-1.05-.046zm2.094 2.025"/>
</svg>
                </div>
              </header> */}
              <div className='mob-cover'>
              <img src={sumsangS } alt="" class="img-fluid" />
              </div> 
            </section>

            <footer class="card__footer-product">
              <div class="card__job-summary-product">
                
                <div class="card__job">
                  <p class="card__job-title">
                  Samsung Galaxy S24 Plus Space Buddies
                  </p>
                </div>
                <div className='d-flex align-items-center justify-content-between'>
                <p class="card__job-title-product">
                  ₹ 599.00
                  </p>
              <button className='add-cart-product-btn'>Add to Cart</button>
              </div>
              </div>
             
              
              
            </footer>
          </div>
        </div>
        <div className='col-lg-3 col-md-3 mb-3'>
          <div class="card">
            <section class="card__hero">
              {/* <header class="card__hero-header mt-1 mb-2">
                <span className='h6'>Select your device</span>
                <div class="card__icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-hand-index-thumb" viewBox="0 0 16 16">
<path d="M6.75 1a.75.75 0 0 1 .75.75V8a.5.5 0 0 0 1 0V5.467l.086-.004c.317-.012.637-.008.816.027.134.027.294.096.448.182.077.042.15.147.15.314V8a.5.5 0 0 0 1 0V6.435l.106-.01c.316-.024.584-.01.708.04.118.046.3.207.486.43.081.096.15.19.2.259V8.5a.5.5 0 1 0 1 0v-1h.342a1 1 0 0 1 .995 1.1l-.271 2.715a2.5 2.5 0 0 1-.317.991l-1.395 2.442a.5.5 0 0 1-.434.252H6.118a.5.5 0 0 1-.447-.276l-1.232-2.465-2.512-4.185a.517.517 0 0 1 .809-.631l2.41 2.41A.5.5 0 0 0 6 9.5V1.75A.75.75 0 0 1 6.75 1M8.5 4.466V1.75a1.75 1.75 0 1 0-3.5 0v6.543L3.443 6.736A1.517 1.517 0 0 0 1.07 8.588l2.491 4.153 1.215 2.43A1.5 1.5 0 0 0 6.118 16h6.302a1.5 1.5 0 0 0 1.302-.756l1.395-2.441a3.5 3.5 0 0 0 .444-1.389l.271-2.715a2 2 0 0 0-1.99-2.199h-.581a5 5 0 0 0-.195-.248c-.191-.229-.51-.568-.88-.716-.364-.146-.846-.132-1.158-.108l-.132.012a1.26 1.26 0 0 0-.56-.642 2.6 2.6 0 0 0-.738-.288c-.31-.062-.739-.058-1.05-.046zm2.094 2.025"/>
</svg>
                </div>
              </header> */}
              <div className='mob-cover'>
              <img src={sumsangTwo  } alt="" class="img-fluid" />
              </div> 
            </section>

            <footer class="card__footer-product">
              <div class="card__job-summary-product">
                
                <div class="card__job">
                  <p class="card__job-title">
                  Samsung Galaxy S24 Plus Space Buddies
                  </p>
                </div>
                <div className='d-flex align-items-center justify-content-between'>
                <p class="card__job-title-product">
                  ₹ 599.00
                  </p>
              <button className='add-cart-product-btn'>Add to Cart</button>
              </div>
              </div>
             
              
              
            </footer>
          </div>
        </div>
        <div  className='col-lg-3 col-md-3  mb-3'>
          <div class="card">
            <section class="card__hero">
              {/* <header class="card__hero-header mt-1 mb-2">
                <span className='h6'>Select your device</span>
                <div class="card__icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-hand-index-thumb" viewBox="0 0 16 16">
<path d="M6.75 1a.75.75 0 0 1 .75.75V8a.5.5 0 0 0 1 0V5.467l.086-.004c.317-.012.637-.008.816.027.134.027.294.096.448.182.077.042.15.147.15.314V8a.5.5 0 0 0 1 0V6.435l.106-.01c.316-.024.584-.01.708.04.118.046.3.207.486.43.081.096.15.19.2.259V8.5a.5.5 0 1 0 1 0v-1h.342a1 1 0 0 1 .995 1.1l-.271 2.715a2.5 2.5 0 0 1-.317.991l-1.395 2.442a.5.5 0 0 1-.434.252H6.118a.5.5 0 0 1-.447-.276l-1.232-2.465-2.512-4.185a.517.517 0 0 1 .809-.631l2.41 2.41A.5.5 0 0 0 6 9.5V1.75A.75.75 0 0 1 6.75 1M8.5 4.466V1.75a1.75 1.75 0 1 0-3.5 0v6.543L3.443 6.736A1.517 1.517 0 0 0 1.07 8.588l2.491 4.153 1.215 2.43A1.5 1.5 0 0 0 6.118 16h6.302a1.5 1.5 0 0 0 1.302-.756l1.395-2.441a3.5 3.5 0 0 0 .444-1.389l.271-2.715a2 2 0 0 0-1.99-2.199h-.581a5 5 0 0 0-.195-.248c-.191-.229-.51-.568-.88-.716-.364-.146-.846-.132-1.158-.108l-.132.012a1.26 1.26 0 0 0-.56-.642 2.6 2.6 0 0 0-.738-.288c-.31-.062-.739-.058-1.05-.046zm2.094 2.025"/>
</svg>
                </div>
              </header> */}
              <div className='mob-cover'>
              <img src={sumsangThree } alt="" class="img-fluid" />
              </div> 
            </section>

            <footer class="card__footer-product">
              <div class="card__job-summary-product">
                
                <div class="card__job">
                  <p class="card__job-title">
                  Samsung Galaxy S24 Plus Space Buddies
                  </p>
                </div>
                <div className='d-flex align-items-center justify-content-between'>
                <p class="card__job-title-product">
                  ₹ 599.00
                  </p>
              <button className='add-cart-product-btn'>Add to Cart</button>
              </div>
              </div>
             
              
              
            </footer>
          </div>
        </div>
        <div className='col-lg-3 col-md-3 mb-3'>
          <div class="card">
            <section class="card__hero">
              {/* <header class="card__hero-header mt-1 mb-2">
                <span className='h6'>Select your device</span>
                <div class="card__icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-hand-index-thumb" viewBox="0 0 16 16">
<path d="M6.75 1a.75.75 0 0 1 .75.75V8a.5.5 0 0 0 1 0V5.467l.086-.004c.317-.012.637-.008.816.027.134.027.294.096.448.182.077.042.15.147.15.314V8a.5.5 0 0 0 1 0V6.435l.106-.01c.316-.024.584-.01.708.04.118.046.3.207.486.43.081.096.15.19.2.259V8.5a.5.5 0 1 0 1 0v-1h.342a1 1 0 0 1 .995 1.1l-.271 2.715a2.5 2.5 0 0 1-.317.991l-1.395 2.442a.5.5 0 0 1-.434.252H6.118a.5.5 0 0 1-.447-.276l-1.232-2.465-2.512-4.185a.517.517 0 0 1 .809-.631l2.41 2.41A.5.5 0 0 0 6 9.5V1.75A.75.75 0 0 1 6.75 1M8.5 4.466V1.75a1.75 1.75 0 1 0-3.5 0v6.543L3.443 6.736A1.517 1.517 0 0 0 1.07 8.588l2.491 4.153 1.215 2.43A1.5 1.5 0 0 0 6.118 16h6.302a1.5 1.5 0 0 0 1.302-.756l1.395-2.441a3.5 3.5 0 0 0 .444-1.389l.271-2.715a2 2 0 0 0-1.99-2.199h-.581a5 5 0 0 0-.195-.248c-.191-.229-.51-.568-.88-.716-.364-.146-.846-.132-1.158-.108l-.132.012a1.26 1.26 0 0 0-.56-.642 2.6 2.6 0 0 0-.738-.288c-.31-.062-.739-.058-1.05-.046zm2.094 2.025"/>
</svg>
                </div>
              </header> */}
              <div className='mob-cover'>
              <img src={sumsangFour} alt="" class="img-fluid" />
              </div> 
            </section>

            <footer class="card__footer-product">
              <div class="card__job-summary-product">
                
                <div class="card__job">
                  <p class="card__job-title">
                  Samsung Galaxy S24 Plus Space Buddies
                  </p>
                </div>
                <div className='d-flex align-items-center justify-content-between'>
                <p class="card__job-title-product">
                  ₹ 599.00
                  </p>
              <button className='add-cart-product-btn'>Add to Cart</button>
              </div>
              </div>
             
              
              
            </footer>
          </div>
        </div>
        <div  className='col-lg-3 col-md-3 mb-3'>
          <div class="card">
            <section class="card__hero">
              {/* <header class="card__hero-header mt-1 mb-2">
                <span className='h6'>Select your device</span>
                <div class="card__icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-hand-index-thumb" viewBox="0 0 16 16">
<path d="M6.75 1a.75.75 0 0 1 .75.75V8a.5.5 0 0 0 1 0V5.467l.086-.004c.317-.012.637-.008.816.027.134.027.294.096.448.182.077.042.15.147.15.314V8a.5.5 0 0 0 1 0V6.435l.106-.01c.316-.024.584-.01.708.04.118.046.3.207.486.43.081.096.15.19.2.259V8.5a.5.5 0 1 0 1 0v-1h.342a1 1 0 0 1 .995 1.1l-.271 2.715a2.5 2.5 0 0 1-.317.991l-1.395 2.442a.5.5 0 0 1-.434.252H6.118a.5.5 0 0 1-.447-.276l-1.232-2.465-2.512-4.185a.517.517 0 0 1 .809-.631l2.41 2.41A.5.5 0 0 0 6 9.5V1.75A.75.75 0 0 1 6.75 1M8.5 4.466V1.75a1.75 1.75 0 1 0-3.5 0v6.543L3.443 6.736A1.517 1.517 0 0 0 1.07 8.588l2.491 4.153 1.215 2.43A1.5 1.5 0 0 0 6.118 16h6.302a1.5 1.5 0 0 0 1.302-.756l1.395-2.441a3.5 3.5 0 0 0 .444-1.389l.271-2.715a2 2 0 0 0-1.99-2.199h-.581a5 5 0 0 0-.195-.248c-.191-.229-.51-.568-.88-.716-.364-.146-.846-.132-1.158-.108l-.132.012a1.26 1.26 0 0 0-.56-.642 2.6 2.6 0 0 0-.738-.288c-.31-.062-.739-.058-1.05-.046zm2.094 2.025"/>
</svg>
                </div>
              </header> */}
              <div className='mob-cover'>
              <img src={sumsangThree } alt="" class="img-fluid" />
              </div> 
            </section>

            <footer class="card__footer-product">
              <div class="card__job-summary-product">
                
                <div class="card__job">
                  <p class="card__job-title">
                  Samsung Galaxy S24 Plus Space Buddies
                  </p>
                </div>
                <div className='d-flex align-items-center justify-content-between'>
                <p class="card__job-title-product">
                  ₹ 599.00
                  </p>
              <button className='add-cart-product-btn'>Add to Cart</button>
              </div>
              </div>
             
              
              
            </footer>
          </div>
        </div>
        <div  className='col-lg-3 col-md-3 mb-3'>
          <div class="card">
            <section class="card__hero">
              {/* <header class="card__hero-header mt-1 mb-2">
                <span className='h6'>Select your device</span>
                <div class="card__icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-hand-index-thumb" viewBox="0 0 16 16">
<path d="M6.75 1a.75.75 0 0 1 .75.75V8a.5.5 0 0 0 1 0V5.467l.086-.004c.317-.012.637-.008.816.027.134.027.294.096.448.182.077.042.15.147.15.314V8a.5.5 0 0 0 1 0V6.435l.106-.01c.316-.024.584-.01.708.04.118.046.3.207.486.43.081.096.15.19.2.259V8.5a.5.5 0 1 0 1 0v-1h.342a1 1 0 0 1 .995 1.1l-.271 2.715a2.5 2.5 0 0 1-.317.991l-1.395 2.442a.5.5 0 0 1-.434.252H6.118a.5.5 0 0 1-.447-.276l-1.232-2.465-2.512-4.185a.517.517 0 0 1 .809-.631l2.41 2.41A.5.5 0 0 0 6 9.5V1.75A.75.75 0 0 1 6.75 1M8.5 4.466V1.75a1.75 1.75 0 1 0-3.5 0v6.543L3.443 6.736A1.517 1.517 0 0 0 1.07 8.588l2.491 4.153 1.215 2.43A1.5 1.5 0 0 0 6.118 16h6.302a1.5 1.5 0 0 0 1.302-.756l1.395-2.441a3.5 3.5 0 0 0 .444-1.389l.271-2.715a2 2 0 0 0-1.99-2.199h-.581a5 5 0 0 0-.195-.248c-.191-.229-.51-.568-.88-.716-.364-.146-.846-.132-1.158-.108l-.132.012a1.26 1.26 0 0 0-.56-.642 2.6 2.6 0 0 0-.738-.288c-.31-.062-.739-.058-1.05-.046zm2.094 2.025"/>
</svg>
                </div>
              </header> */}
              <div className='mob-cover'>
              <img src={sumsangTwo } alt="" class="img-fluid" />
              </div> 
            </section>

            <footer class="card__footer-product">
              <div class="card__job-summary-product">
                
                <div class="card__job">
                  <p class="card__job-title">
                  Samsung Galaxy S24 Plus Space Buddies
                  </p>
                </div>
                <div className='d-flex align-items-center justify-content-between'>
                <p class="card__job-title-product">
                  ₹ 599.00
                  </p>
              <button className='add-cart-product-btn'>Add to Cart</button>
              </div>
              </div>
             
              
              
            </footer>
          </div>
        </div>
        <div className='col-lg-3 col-md-3 mb-3'>
          <div class="card">
            <section class="card__hero">
              {/* <header class="card__hero-header mt-1 mb-2">
                <span className='h6'>Select your device</span>
                <div class="card__icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-hand-index-thumb" viewBox="0 0 16 16">
<path d="M6.75 1a.75.75 0 0 1 .75.75V8a.5.5 0 0 0 1 0V5.467l.086-.004c.317-.012.637-.008.816.027.134.027.294.096.448.182.077.042.15.147.15.314V8a.5.5 0 0 0 1 0V6.435l.106-.01c.316-.024.584-.01.708.04.118.046.3.207.486.43.081.096.15.19.2.259V8.5a.5.5 0 1 0 1 0v-1h.342a1 1 0 0 1 .995 1.1l-.271 2.715a2.5 2.5 0 0 1-.317.991l-1.395 2.442a.5.5 0 0 1-.434.252H6.118a.5.5 0 0 1-.447-.276l-1.232-2.465-2.512-4.185a.517.517 0 0 1 .809-.631l2.41 2.41A.5.5 0 0 0 6 9.5V1.75A.75.75 0 0 1 6.75 1M8.5 4.466V1.75a1.75 1.75 0 1 0-3.5 0v6.543L3.443 6.736A1.517 1.517 0 0 0 1.07 8.588l2.491 4.153 1.215 2.43A1.5 1.5 0 0 0 6.118 16h6.302a1.5 1.5 0 0 0 1.302-.756l1.395-2.441a3.5 3.5 0 0 0 .444-1.389l.271-2.715a2 2 0 0 0-1.99-2.199h-.581a5 5 0 0 0-.195-.248c-.191-.229-.51-.568-.88-.716-.364-.146-.846-.132-1.158-.108l-.132.012a1.26 1.26 0 0 0-.56-.642 2.6 2.6 0 0 0-.738-.288c-.31-.062-.739-.058-1.05-.046zm2.094 2.025"/>
</svg>
                </div>
              </header> */}
              <div className='mob-cover'>
              <img src={sumsangS } alt="" class="img-fluid" />
              </div> 
            </section>

            <footer class="card__footer-product">
              <div class="card__job-summary-product">
                
                <div class="card__job">
                  <p class="card__job-title">
                  Samsung Galaxy S24 Plus Space Buddies
                  </p>
                </div>
                <div className='d-flex align-items-center justify-content-between'>
                <p class="card__job-title-product">
                  ₹ 599.00
                  </p>
              <button className='add-cart-product-btn'>Add to Cart</button>
              </div>
              </div>
             
              
              
            </footer>
          </div>
        </div>
        <div className='col-lg-3 col-md-3 mb-3'>
          <div class="card">
            <section class="card__hero">
              {/* <header class="card__hero-header mt-1 mb-2">
                <span className='h6'>Select your device</span>
                <div class="card__icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-hand-index-thumb" viewBox="0 0 16 16">
<path d="M6.75 1a.75.75 0 0 1 .75.75V8a.5.5 0 0 0 1 0V5.467l.086-.004c.317-.012.637-.008.816.027.134.027.294.096.448.182.077.042.15.147.15.314V8a.5.5 0 0 0 1 0V6.435l.106-.01c.316-.024.584-.01.708.04.118.046.3.207.486.43.081.096.15.19.2.259V8.5a.5.5 0 1 0 1 0v-1h.342a1 1 0 0 1 .995 1.1l-.271 2.715a2.5 2.5 0 0 1-.317.991l-1.395 2.442a.5.5 0 0 1-.434.252H6.118a.5.5 0 0 1-.447-.276l-1.232-2.465-2.512-4.185a.517.517 0 0 1 .809-.631l2.41 2.41A.5.5 0 0 0 6 9.5V1.75A.75.75 0 0 1 6.75 1M8.5 4.466V1.75a1.75 1.75 0 1 0-3.5 0v6.543L3.443 6.736A1.517 1.517 0 0 0 1.07 8.588l2.491 4.153 1.215 2.43A1.5 1.5 0 0 0 6.118 16h6.302a1.5 1.5 0 0 0 1.302-.756l1.395-2.441a3.5 3.5 0 0 0 .444-1.389l.271-2.715a2 2 0 0 0-1.99-2.199h-.581a5 5 0 0 0-.195-.248c-.191-.229-.51-.568-.88-.716-.364-.146-.846-.132-1.158-.108l-.132.012a1.26 1.26 0 0 0-.56-.642 2.6 2.6 0 0 0-.738-.288c-.31-.062-.739-.058-1.05-.046zm2.094 2.025"/>
</svg>
                </div>
              </header> */}
              <div className='mob-cover'>
              <img src={sumsangFour } alt="" class="img-fluid" />
              </div> 
            </section>

            <footer class="card__footer-product">
              <div class="card__job-summary-product">
                
                <div class="card__job">
                  <p class="card__job-title">
                  Samsung Galaxy S24 Plus Space Buddies
                  </p>
                </div>
                <div className='d-flex align-items-center justify-content-between'>
                <p class="card__job-title-product">
                  ₹ 599.00
                  </p>
              <button className='add-cart-product-btn'>Add to Cart</button>
              </div>
              </div>
             
              
              
            </footer>
          </div>
        </div>
        </div>
        </div>
        </section>
    </div>
  )
}

export default BrandModelSkinCollections
