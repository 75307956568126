import React from 'react';
import mobileOne from '../../assets/Images/collection_banners_1_1.jpg';
import bannerImg from '../../assets/Images/iphone16_banner_DESKTOP.jpg';
import skinBanner from '../../assets/Images/skin_banner_2_copy.jpg';


const CarouselHero = () => {
  return (
    <div>
         <div id="carouselExampleAutoplaying" class="carousel slide" data-bs-ride="carousel">
  <div class="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
  </div>
  <div class="carousel-inner">
    <div class="carousel-item active">
      <img src={mobileOne} class="d-block w-100" alt="..."/>
      
    </div>
    <div class="carousel-item">
      <img src={bannerImg} class="d-block w-100" alt="..."/>
      
    </div>
    <div class="carousel-item">
      <img src={skinBanner} class="d-block w-100" alt="..."/>
     
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>
    </div>
  )
}

export default CarouselHero